// }

import { AnimatePresence } from 'framer-motion';
import React, { lazy, Suspense, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import PageLoader from '@/components/PageLoader';
import { settingsAction } from '@/redux/settings/actions';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { routesConfig } from './RoutesConfig';

// const Logout = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/pages/Logout'));
const Logout = lazy(() => import('@/pages/Logout'));
// const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));
const NotFound = lazy(() => import('@/pages/NotFound'));

// eslint-disable-next-line no-unused-vars
const SubMenuRouter = ({ subMenuRouter }) => {
  subMenuRouter.map((subMenu) => {
    console.log('🚀 ~ file: AppRouter.jsx ~ line 25 ~ routeItem.hasSubMenu.map ~ subMenu', subMenu);

    return (
      <PrivateRoute
        key={subMenu.component}
        path={subMenu.path}
        exact={subMenu.exact || true}
        component={lazy(
          // () => import(/* webpackChunkName: "[request]" */ `@/pages/${subMenu.component}`)
          () => import(`@/pages/${subMenu.component}`)
        )}
      />
    );
  });
};

export default function AppRouter() {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(settingsAction.list({ entity: 'setting' }));
  }, []);
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          {routesConfig.map((routeItem) => {
            return (
              <PrivateRoute
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact || true}
                component={lazy(
                  // () => import(/* webpackChunkName: "[request]" */ `@/pages/${routeItem.component}`)
                  () => import(`@/pages/${routeItem.component}`)
                )}
              />
            );
          })}
          <PublicRoute path="/login" render={() => <Redirect to="/" />} exact />
          <Route component={Logout} path="/logout" exact />
          <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
